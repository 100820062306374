/* Home2.css */
.home-about-section {
  background-color: #f8f9fa;
  padding: 40px 0;
}

.primary-header {
  color: #007bff;
}

.home-about-description {
  text-align: left;
}

.home-about-body {
  font-size: 1.2em;
  
}



.myAvtar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myAvtar img {
  max-width: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-about-social {
  text-align: center;
}

.home-about-social h1 {
  font-size: 2em;
}

.home-about-social p {
  font-size: 1.2em;
  color: #777;
}

.home-about-social-links {
  list-style: none;
  padding: 0;
}

.social-icons {
  display: inline-block;
  margin: 0 10px;
}

.icon-colour {
  color: #007bff;
}

.avatar-trigger {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.avatar-trigger.active {
  opacity: 1;
  transform: translateY(0);
}

